.Footer {
  backdrop-filter: brightness(90%) blur(10px) saturate(50%);
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding: 0;
  width: 100%;
}

.footer-items-container {
  display: flex;
  margin: 0 auto;
  padding: 1em;
  max-width: 1200px;
}

.footer-section {
  height: 100%;
  margin: 0 auto;
  width: auto;
  align-items: start;
}

.footer-about-headline {
  color: var(--dark-mode-text);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
}

.footer-contact {
  width: 16em;
  margin-left: 1em;
}

.nav-link {
  text-decoration: none;
}

.footer-about-p {
  text-align: left;
  font-size: 20px;
}

.footer-dark-mode-container .dark-mode-container-fullsize {
  margin: auto;
}

.links-container {
  margin: auto;
  width: auto;
}

.footer-link {
  display: flex;
  margin: 5px 0;
  justify-content: flex-start;
  height: 1em;
}

.footer-link:hover .footer-text {
  filter: invert(25%);
}

.footer-icon {
  margin: 0;
  height: 25px;
}

.bw-icon {
  filter: var(--bw-icon-filter);
}

.footer-text {
  text-decoration: underline;
  font-size: 15px;
  font-weight: 800;
  color: #aaa;
  margin: auto 1em;
}

.footer-text-location {
  text-decoration: none;
}


/* 840px ------------------------------------------------------ */
@media (max-width: 840px) {
  .footer-items-container {
    display: block;
    padding: 0;
  }

  .footer-about {
    padding: 1em;
    box-sizing: border-box;
  }

  .footer-section {
    width: 100%;
    margin: auto;
  }

  .footer-contact * {
    visibility: hidden;
    height: 0;
  }

  .footer-link {
    height: 0;
  }
  
  .footer-link-location {
    visibility: hidden;
  }

  .nav-link-container {
    padding-bottom: 1em;
  }
  
  .nav-link {
    display: block;
    margin: 1em auto 0;
    text-decoration: none;
    width: 75%;
    padding: 1em;
    background-color: var(--background-accent-color);
    color: var(--light-text);
    font-weight: 600;
    border-radius: 5px;
  }
}