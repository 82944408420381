h2, h3 {
  color: var(--light-text);
  margin: 0;
}

.App {
  color: var(--light-text);
  text-align: center;
  background-color: var(--background-color);
}

.Page {
  display: flex;
  flex-direction: column;
}

.app-main {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* max-width: 1200px; */
  margin: 0 auto;
  /* padding: 1em; */
}

/* 1240px ------------------------------------------------------ */
@media (max-width: 1240px) {
  .App {
    margin: auto;
  }
 
  .app-main {
    max-width: 1000px;
    padding: 2em;
  }
}

/* 1000px ------------------------------------------------------ */
@media (max-width: 1000px) {
  .app-main {
    padding: 0;
  }
}