
.App-header {
  color: #696969;
  /* background-color: var(--header-background-color); */
  padding: 8px;
  padding-top: 12px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid var(--dark-mode-text);
  position: sticky;
  backdrop-filter: blur(10px);
  top: -4px;
}

.app-header-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dark-mode-container-fullsize {
  width: 220px;
  display: flex;
  text-decoration: none;
  font-size: 10px;
}

.header-nav-container {
  display: inline-flex;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
}

.header-link {
  font-size: 20px;
  text-decoration: none;
  color: var(--light-text);
  margin: auto 0;
  transition-duration: .2s;
}

.header-link-active {
  font-weight: 600;
}

.header-link:hover {
  color: var(--medium-text);
}

.dark-mode-text-fullsize {
  margin: auto 10px;
  font-size: 18px;
  font-weight: 200;
  color: var(--dark-mode-text);
}

.app-header-name {
  display: flex;
  justify-content: space-between;
  width: auto;
  cursor: pointer;
  text-decoration: none;
}

.app-AB-icon {
  height: 36px;
  filter: var(--svg-filter);
  margin: 0;
}

.header-title {
  font-size: 2em;
  color: var(--light-text);
  margin: auto 0;
}
      
/* 2000px  ----------------------------------------------------------------------- */
@media (max-width: 2000px) {
  .App-header {
  }
  
  .app-header-container {
  }
  
  .header-nav-container {
    width: 50%;
  }
  
  .dark-mode-container-fullsize {
    justify-content: end;
  }
}


/* 1240px  ----------------------------------------------------------------------- */
@media (max-width: 1240px) {
  .App-header {
    box-sizing: border-box;
  }
  
  .app-header-container {
    max-width: 1000px;
  }
}

@media (max-width: 1000px) {
  .App-header {
    height: unset;
  }
  
  .app-header-container {
    display: block;
  }
  
  .app-header-top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .header-hamburger-menu-container {
    transition-duration: .3s;
    cursor: pointer;
  }
  
  .header-hamburger-menu-container:hover .header-hamburger-bar {
    background-color: var(--tldr-p-background-color);
  }
  
  .header-hamburger-container {
    height: 35px;
    width: 35px;
    cursor: pointer;
    z-index: 20;
  }
  
  .header-hamburger-bar {
    background-color: var(--light-text);
    height: 2px;
    border-radius: 3px;
    width: 35px;
    margin: 0;
    position: relative;
    z-index: 12;
    transition-duration: .2s;
  }

  .hamburger-bar1 {
    top: 4px;
  }
  
  .hamburger-bar2 {
    top: 14px;
  }
  
  .hamburger-bar3 {
    top: 24px;
  }
  
  .header-hamburger-container-active > .hamburger-bar1 {
    top: 12px;
    box-shadow: none;
    transform: rotate(45deg);
  }
  
  .header-hamburger-container-active > .hamburger-bar2 {
    top: 10px;
    box-shadow: none;
    transform: rotate(-45deg);
  }
  
  .header-hamburger-container-active > .hamburger-bar3 {
    top: 8px;
    box-shadow: none;
    transform: rotate(135deg);
  }
  
  .app-AB-icon {
    height: 30px;
  }
  
  .header-title {
    font-size: 1.5em;
  }
}

@media (max-width: 800px) {
  .App-header-active-sidebar {
    min-width: calc(10vw - 20px);
  }
}