:root {
  --header-background-color: #282828;
  --background-color: #001f3f;
  --background-accent-color: #333;
  --background-accent-color-shifted: #555;
  --accent-orange: #E67E22;
  --tldr-p-background-color: #147efb;
  --tldr-p-background-color-gradient-ios: linear-gradient(180deg, #5fc9f8 1%, #147efb 100%);
  --tldr-p-background-color-gradient: linear-gradient(180deg, #4ca1c6 1%, #1069c8 75%);
  --blue-blue-background-gradient: linear-gradient(180deg, #5fc9f8, #147efb);
  --blue-to-main-bg-background: linear-gradient(180deg, #147efb 0%, #232323 60%);
  --high-contrast-text-over-blue-bg: #232323;
  --accent-blue: #0077b6;
  --big-shadow: -.5em 1em 2em #111;
  --colorful-shadow:  8em 8em 5em -10em #fecb2e,
                      -8em -8em 5em -10em #fc3158,
                      8em -8em 5em -10em #53d769,
                      -8em 8em 5em -10em #5fc9f8;
  --colorful-shadow-small:  16px 16px 10px -15px #147efb,
                            -16px -16px 10px -15px #4DB7F9,
                            16px -16px 10px -15px #147efb,
                            -16px 16px 10px -15px #4DB7F9;
  --colorful-shadow-animation: pulsate-shadow 5s infinite alternate;
  --bigger-shadow: -.5em 1em 3em #111;
  --shadow-to-the-right: 10px 0 20px -10px #111;
  --light-text: #ddd;
  --medium-text: #bbb;
  --main-p-weight: 400;
  --main-p-size: 1.5em;
  --svg-filter: invert(90%);
  --header-height: 80px;
  --small-header-height: 50px;
  --bw-icon-filter: invert(90%);
  --container-margin:  3em 0;
  --dark-mode-text: #94DAFF;
  
  /* header text styles */
  --font-size: 4em;
  --font-size-small: 2em;
  --font-weight: 600;
  --text-align: left;
  --background-clip: text;
  --webkit-background-clip: text;
  --webkit-text-fill-color: transparent;
  
  
  --dm-slider-dot-top: 5px;
  --dm-slider-dot-left: 45px;
  --slider-shadow: 0 0 5px black;
}

[data-theme="light"] {
  --header-background-color: #ccc;
  --background-color: #ddd;
  --background-accent-color: #bbb;
  --background-accent-color-shifted: #999;
  --tldr-p-background-color: #147efb;
  --blue-blue-background-gradient: linear-gradient(180deg, #0033FE, #147efb);
  --blue-to-main-bg-background: linear-gradient(180deg, #147efb 0%, #ddd 100%);
  --high-contrast-text-over-blue-bg: #eee;
  --accent-blue: #0077b6;
  --big-shadow: -.5em 1em 2em #696969;
  --bigger-shadow: -.5em 1em 3em #666;
  --shadow-to-the-right: 1px 0 20px -10px #000;
  --light-text: #232323;
  --medium-text: #555;
  --svg-filter: invert(10%);
  --bw-icon-filter: invert(10%);
  --dark-mode-text: #002E55;
  
  --dm-slider-dot-top: 5px;
  --dm-slider-dot-left: 5px;
  --slider-shadow: 0 0 5px #666;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  overflow-x: hidden;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
