.Tldr {
  margin: var(--container-margin);
}

.tldr-header {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  text-align: var(--text-align);
  background-clip: var(--background-clip);
  background: var(--blue-blue-background-gradient);
  -webkit-background-clip: var(--webkit-background-clip);
  -webkit-text-fill-color: var(--webkit-text-fill-color);
}

.tldr-sub-header {
  font-size: var(--main-p-size);
  text-align: left;
  padding: 1em 0;
}

.specialties {
  text-align: center;
  padding: 0;
}

.tldr-text-container {
  margin: var(--container-margin);
}

.tldr-section {
  box-shadow: 0 0 25px -15px var(--background-color);
  border-radius: 20px 20px  20px 0;
  text-align: start;
  font-size: 2em;
  font-weight: 900;
  background-color: var(--background-accent-color);
  width: fit-content;
  padding: .25em .5em;
  margin: 10px 0;
}

.tldr-p {
  border-radius: 1em 1em 0 1em;
  background: var(--tldr-p-background-color-gradient);
  background-attachment: fixed;
  color: #ddd;
  box-shadow: 0 2px 10px -3px #111;
  margin: 0 0 1em 3em;
  padding: 1em 1em 1em 1em;
  font-size: 1.25em;
}

.tldr-link {
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.tldr-link:visited {
  color: unset;
}

.tldr-message-read {
  text-align: end;
  font-weight: 600;
  color: #999;
}

.learn-more-button {
  color: var(--dark-mode-text);
  cursor: pointer;
  font-size: 1.5em;
  width: fit-content;
  margin: 1em 0 0 2em;
  padding: .25em .5em;
  box-shadow: 5px 5px var(--dark-mode-text), -5px -5px var(--dark-mode-text);
  transition: ease-in-out 250ms;
  text-decoration: none;
}
.learn-more-button:hover {
  border-radius: 2px;
  box-shadow: 8px 8px var(--dark-mode-text), -8px -8px var(--dark-mode-text);
}

@media (max-width: 1240px) {
  .tldr-section {
    padding: .25em 1em;
  }
  .tldr-p {
    margin-left: 20%;
    padding: 1em 1.25em;
  }
}

@media (max-width: 1000px) {
  .Tldr {
    padding: 2em;
  }
}

@media (max-width: 800px) {
  .Tldr {
    padding: 1em;
    margin-top: unset;
  }

  .tldr-header {
    font-size: var(--font-size-small);
  }

  .tldr-sub-header {
    font-size: 1.25em;
  }

  .specialties {
    visibility: hidden;
    height: 0;
  }

  .tldr-text-container {
    margin-top: unset;
  }

  .tldr-section {
    font-size: var(--font-size-small);
  }

  .tldr-p {
    background: var(--tldr-p-background-color-gradient-ios);
    background-attachment: fixed;
  }
}
