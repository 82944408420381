/* Main home section */

.content-container {
  max-width: 1200px;
  margin: auto;
}

.home-main-container {
  background: var(--blue-to-main-bg-background);
}

.main-content-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
}

.home-text {
  text-align: left;
  margin-right: 2em;
  max-width: 60%;
}

.home-headline {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: 1.2;
  text-align: var(--text-align);
  color: #FFF;
  /* background-clip: var(--background-clip);
  background: var(--blue-blue-background-gradient);
  -webkit-background-clip: var(--webkit-background-clip);
  -webkit-text-fill-color: var(--webkit-text-fill-color); */
}

.page-header {
  font-size: 120px;
  margin: 0;
  letter-spacing: -4px;
}

.main-paragraph {
  text-align: left;
  margin-top: 0;
}

.home-p {
  font-weight: var(--main-p-weight);
  font-size: var(--main-p-size);
  line-height: 1.2;
}

.home-image-container {
  padding: 3em 0;
  width: 40%;
  /* background-color: #E67E22; */
}

.profile-img-home {
  cursor: pointer;
  /* border-radius: 4px; */
  object-fit: contain;
  max-width: 100%;
  transition-duration: .25s;
  border: 8px solid var(--accent-orange);
  box-shadow: 0 0 80px -40px rgba(255, 255, 255, .7);
}

.image-tooltip {
  background-color: var(--background-accent-color);
  opacity: 75%;
  position: relative;
  bottom: 3em;
  padding: .5em 1em;
  box-sizing: border-box;
  border-radius: 2em;
  font-size: small;
  cursor: pointer;
  visibility: hidden;
}

.profile-img-home:hover ~ .image-tooltip {
  visibility: visible;
}

/* Links section */

.home-links-container {
  width: 100%;
  margin: var(--container-margin);
  display: flex;
  justify-content: space-around;
}

.home-link {
  /* background-color: var(--background-accent-color); */
  border: 2px solid var(--light-text);
  color: var(--light-text);
  font-size: 2em;
  font-weight: 600;
  border-radius: 50px;
  padding: 10px 40px;
  text-decoration: none;
  margin: auto;
  transition-duration: .2s;
}

.home-link:hover {
  background-color: #147efb;
  color: var(--background-color);
  box-shadow: 10px 10px 20px rgba(var(--background-color), 1);
}

.resume-download a {
  text-decoration: none;
  color: var(--light-text);
}

/* Team section */

.home-team-container {
  margin: var(--container-margin);
}

.home-divider {
  background-color: var(--background-accent-color-shifted);
  border-radius: 50%;
  height: 2px;
  width: 80%;
  margin: 1em auto;
}

.home-team-headline {
  text-align: center;
}

.home-team-sub-headline {
  margin: .5em 0 1.5em;
}

.home-team-paragraph ul {
  text-align: left;
  margin-bottom: 4em;
}

.home-team-paragraph li {
  margin: 1em 0;
}

.team-li-big {
  background: var(--blue-blue-background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.home-link-contact {
  margin: auto;
  padding:  10px 2em;
}

/* Technologies I've Used section */

.tech-container-background {
  margin-top: -1px;
  color: var(--light-text);
}

.tech-container {
  border-radius: 1em;
  padding: 2em 0;
  /* margin: var(--container-margin); */
}

.tech-logo-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logo-group {
  max-width: 8%;
  padding: 3em;
}

.tech-icon {
  border-radius: 1.5em;
  height: 100%;
  width: 100%;
}

.icon-name {
  color: var(--light-text);
  letter-spacing: 2px;
}

/* 1240px ------------------------------------------------------ */
@media (max-width: 1240px) {
  .home-main-container {
    display: block;
  }

  .home-headline {
    margin: 0;
    font-size: 3em;
    text-align: center;
  }

  .home-text {
    max-width: 100%;
    margin: auto;
  }

  .home-link {
    background-color: var(--background-accent-color);
    color: var(--light-text);
  }

  .home-image-container {
    padding: 1em 0;
    width: 50%;
    margin: auto;
  }

  .logo-group {
    padding: 0;
    max-width: 12%;
    margin: 1em;
  }

  .tech-icon {
    height: unset;
  }

  .recommendations-sub-headline {
    text-align: center;
  }
}

/* 1000px ------------------------------------------------------ */
@media (max-width: 1000px) {
  .home-headline {
    font-size: 2em;
  }

  .home-p {
    padding: .5em;
    margin: 0;
  }
  
  .home-link {
    width: 40%;
    padding: 10px 0;
    font-size: 1.5em;
  }
  
  .home-image-container {
    position: relative;
    z-index: 2;
    display: block;
  }
  
  .profile-img-home:hover ~ .image-tooltip {
    visibility: hidden;
  }

  .home-team-container {
    background: var(--blue-blue-background-gradient);
    padding: 4em 10px;
    color: var(--high-contrast-text-over-blue-bg);
    margin-top: -8em;
    margin-bottom: 0;
    padding-bottom: 8em;
  }

  .home-divider {
    visibility: hidden;
    height: 0;
    margin: 0;
  }

  .home-team-headline {
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
  }

  .home-team-paragraph {
    font-size: 20px;
    padding: 1em 0;
  }

  .home-team-paragraph ul {
    padding: 1em;
    padding-right: 0;
    margin-bottom: 0;
  }

  .main-paragraph {
    text-align: left;
  }

  .tech-container-background {
    margin-top: -1px;
    background: var(--blue-to-main-bg-background);
  }
  
  .tech-container {
    margin: -4em 1em 0;
  }

  .team-li-big {
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
  }

  .logo-group {
    padding: 1em;
    max-width: 14%;
    margin: 1em;
  }

  .icon-name {
    visibility: hidden;
    height: 0;
    margin: 0;
  }

  .recommendations {
    padding: 0 1em;
  }
}

/* 800px ------------------------------------------------------ */
@media (max-width: 800px) {
  .home-text {
    padding: 0;
  }
  
  .home-headline {
    font-size: var(--font-size-small);
    text-align: left;
    margin: 0 20px;
  }
  
  .home-links-container {
    flex-direction: column;
    height: unset;
    margin-top: unset;
  }
  
  .home-link {
    width: 80%;
    margin: 1em auto 0;
    padding: 10px;
  }
  
  .home-p {
    font-size: 16px;
    text-align: left;
    margin: 0 20px;
  }

  .home-team-sub-headline {
    padding: .5em 0;
  }

  .home-team-paragraph {
    padding-top: 0;
  }
  
  .home-team-paragraph ul {
    padding-top: 0;
    padding-left: 10px;
    margin-top: 0;
  }

  .recommendations {
    grid-template-columns: auto;
    gap: 1em;
    padding: 1em;
  }

  .recommendations-sub-headline {
    text-align: center;
    padding: 0 1em;
    font-size: 20px;
  }

  .rec-comment {
    height: unset;
  }
}
