.Resume {
  margin: var(--container-margin);
}

.resume-iframe {
  border: none;
  border-radius: 1em;
  box-shadow: var(--big-shadow);
  width: 100%;
  height: 1200px;
}

.resume-iframe:hover {
  box-shadow: var(--bigger-shadow);
}

.resume-download-link {
  background: var(--background-accent-color);
  padding: 1em;
  margin: 4em auto 0;
  max-width: 15em;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition-duration: .2s;
}

.resume-download-link a {
  text-decoration: none;
  font-weight: 600;
  color: var(--light-text);
} 

.resume-download-link:hover a {
  color: var(--accent-blue);
}

/* 800px ------------------------------------------------------ */
@media (max-width: 800px) {
  .Resume {
    padding: 1em;
  }
  
  .resume-iframe {
    max-height: 75vh;
  }
}