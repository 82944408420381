.contact-container {
  margin: var(--container-margin);
}

.contact-header {
  font-size: 2em;
  font-weight: var(--font-weight);
  text-align: center;
  color: var(--medium-text);
}

.contact-form-border {
  background: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);  
  border: none;
  border-radius: 10px;
  margin: 4em auto;
  width: 60%;
  min-width: 550px;
  padding: 5px;
  position: relative;
  overflow: hidden;
}

.contact-form {
  background-color: var(--background-color);
  margin: 0;
  border-radius: 8px;
  background-clip: border-box;
  padding: 3em 2em;
  font-weight: 600;
}

.contact-section {
  display: block;
  margin-bottom: 2em;
}

.contact-label {
  display: flex;
  text-align: left;
  width: 100%;
  margin: 1em;
}

.contact-field {
  background-color: transparent;
  opacity: .4;
  color: var(--light-text);
  font-size: 15px;
  font-weight: 600;
  padding: 1em;
  outline: none;
  border: 5px solid var(--background-accent-color-shifted);
  border-radius: 10px;
  transition-duration: .3s;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
}

.contact-field:focus {
  border: 5px solid var(--light-text);
}

.contact-message-input {
  height: 150px;
}

.form-submit {
  font-size: 1.5em;
  cursor: pointer;
  padding: 5px 3em;
  background-color: var(--background-accent-color);
  border: none;
  border-radius: 10px;
  color: var(--light-text);
  transition-duration: .2s;
}

.form-submit:hover {
  background-color: var(--background-accent-color-shifted);
}

.form-status {
  margin-top: 2em;
}

/* Contact section ------------------------------------------------- */
.contact-link-container {
  display: flex;
  justify-content: space-between;
}

.contact-link {
  display: flex;
  justify-content: space-around;
  width: 280px;
  border: 5px solid var(--background-accent-color);
  border-radius: 10px;
  margin: 1em auto;
  padding: .5em 1em;
  text-decoration: none;
  box-sizing: border-box;
  transition-duration: .2s;
}

.contact-link:hover {
  border: 5px solid var(--light-text);
}

.contact-icon {
  height: 2em;
  margin: auto 0;
}

.contact-text {
  color: var(--light-text);
  font-size: 15px;
}

@media (max-width: 700px) {
  .contact-container {
    margin: 0;
  }
  
  .contact-header {
    text-align: left;
    padding: 0;
    margin: 1em auto;
    width: 95%;
    box-sizing: border-box;
  }

  .contact-form-border {
    min-width: unset;
    width: 95%;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .contact-link-container {
    display: block;
  }

  .contact-link {
    justify-content: center;
    width: 80%;
    padding: .5em 10%;
  }

  .contact-text {
    margin-left: 1em;
    font-size: large;
  }
}