.sidebar {
  background: linear-gradient(0deg, var(--background-accent-color-shifted), var(--background-accent-color) 100%);
  border-radius: 10px;
  padding: 1em;
  padding-bottom: 0;
  font-weight: var(--main-p-weight);
  font-size: var(--main-p-size);
  transition: transform 0.3s ease;
}

.sidebar-section {
  color: var(--light-text);
  font-weight: 600;
  text-align: left;
  display: block;
  width: 100%;
  padding: .5em 0;
  margin-bottom: 10px;
  text-decoration: none;
}

.sidebar-section:hover {
  background: var(--blue-blue-background-gradient);
  background-clip: text;
  text-decoration: underline;
  -webkit-text-fill-color: transparent;
}

.dark-mode-container {
  top: 2em;
  display: flex;
  justify-content: space-between;
  margin: 1em 0 0;
}

.dark-mode-slider {
  cursor: pointer;
  height: 40px;
  border-radius: 20px;
  width: 80px;
  background-color: var(--background-accent-color);
  box-shadow: inset 0 0 7px -5px #ddd;
  padding: 0;
  transition-duration: .2s;
}

.dark-mode-slider-dot {
  height: 30px;
  width: 30px;
  background-color: #ddd;
  border-radius: 50%;
  box-shadow: var(--slider-shadow);
  position: relative;
  top: var(--dm-slider-dot-top);
  left: var(--dm-slider-dot-left);
  transition-duration: .2s;
  z-index: 1;
}

.dark-mode-enabled-dot {
  height: 8px;
  width: 8px;
  background-color: lawngreen;
  border-radius: 50%;
  position: relative;
  bottom: 14px;
  left: 16px;
  z-index: 0;
}

.dark-mode-slider-dot:hover {
  box-shadow: 0 0 8px black;
}

.dark-mode-text {
  margin: auto 0;
  color: var(--accent-blue);
}

@media (max-width: 1000px) {
  .sidebar {
    padding-bottom: 1em;
    margin: 0;
    background: unset;
    box-shadow: none;
    box-sizing: border-box;
  }
}